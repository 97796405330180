<template>
    <div class="zykt-main p-1 h-100 box-border">
        <div class="d-flex ai-center mb-1">
            <zy-cascader
                class="mr-1"
                ref="zyCascader"
                expand-trigger="click"
                :cascaderData="cascaderData"
                lazy
                @lazy-load="handleCascaderLazyLoad"
                @change="handleCascaderChange"
            ></zy-cascader>
            <el-input
                class="mr-1"
                v-model="room"
                placeholder="户号"
                style="width:120px"
            ></el-input>
            <el-select
                class="selectInp mr-1"
                v-model="paySt"
                placeholder="请选择缴费状态"
            >
                <el-option
                    v-for="item in payStOpt"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
            <el-button type="primary" @click="searchTabData">
                搜索
            </el-button>
            <el-button type="primary" @click="ExcelClick">
                <i class="iconfont icon-daochubiaoge"></i>
                导出
            </el-button>
        </div>
        <zy-table :tbHead="tbHead" :tbBody="tbBody" highlightCurrentRow>
        </zy-table>

        <zy-page
            class="search-site-page mt-1"
            @current-change="handlePageCurrentChange"
            @size-change="handlePageSizeChange"
            :pageModel="pageModel"
            :total="total"
            :pageSizes="[15, 20, 50, 300]"
        ></zy-page>
    </div>
</template>

<script>
import ZyTable from '@/components/ZyTable/index'
import ZyPage from '@/mixins/ZyPage'
import Excel from '@/mixins/excel'
import cascader from '@/mixins/select'
export default {
    components: { ZyTable },
    mixins: [ZyPage, cascader, Excel],
    data() {
        return {
            room: '',
            navList: [
                {
                    icon: 'icon-shebeizaixian',
                    // iconColor: '#017EFF',
                    text: '当前在线',
                    num: '50',
                    flag: ''
                },
                {
                    icon: 'icon-xufei',
                    iconColor: '#3FDF59',
                    text: '缴费用户',
                    num: '2',
                    flag: ''
                },
                {
                    icon: 'icon-ic_user_zx',
                    // iconColor: '#FFD400',
                    text: '当前在用户数',
                    num: '2',
                    flag: ''
                }
            ],

            // 计费状态
            paySt: '',
            payStOpt: [
                {
                    value: '全部',
                    label: '全部'
                },
                {
                    value: '已缴费',
                    label: '已缴费'
                },
                {
                    value: '未缴费',
                    label: '未缴费'
                },
                {
                    value: '欠费',
                    label: '欠费'
                }
            ],
            // 表头
            tbHead: [
                {
                    prop: 'acname',
                    label: '小区'
                },
                {
                    prop: 'building',
                    label: '楼栋',
                    width: 50
                },
                {
                    prop: 'unit',
                    label: '单元',
                    width: 50
                },
                {
                    prop: 'room',
                    label: '户号',
                    width: 60
                },
                {
                    prop: 'totalMoney',
                    label: '余额'
                },
                // {
                //     prop: '',
                //     label: '缴费',
                //     width: 50
                // },
                {
                    prop: 'jifeiStatus',
                    label: '计费状态'
                },
                {
                    prop: 'status',
                    label: '运行状态'
                },

                {
                    prop: 'dangWei',
                    label: '当前档位'
                },

                {
                    prop: 'high',
                    label: '高档时间(分)',
                    width: 110
                },
                {
                    prop: 'mid',
                    label: '中档时间(分)',
                    width: 110
                },
                {
                    prop: 'low',
                    label: '低档时间(分)',
                    width: 110
                },
                {
                    prop: 'totalDangLiang',
                    label: '总当量'
                },
                {
                    prop: 'zhouQi',
                    label: '周期(分)',
                    width: 80
                },
                {
                    prop: 'recordTime',
                    label: '上传时间',
                    sortable: true,
                    width: 170
                },
                {
                    prop: 'deviceId',
                    label: 'NB序列号',
                    width: 100,
                    overflow: true
                },
                {
                    prop: 'sim',
                    label: 'SIM序列号',
                    width: 110,
                    overflow: true
                },
                {
                    prop: 'snId',
                    label: 'SN号',
                    overflow: true
                },
                {
                    prop: 'fpId',
                    label: '设备号',
                    width: 100
                },
                {
                    prop: '',
                    label: '室外温度',
                    width: 110
                }
            ],

            // 内容
            tbBody: []
        }
    },
    created() {
        this.getTabData()
    },
    methods: {
        // 获取表格数据
        async getTabData() {
            const pageNum = this.pageModel.pagenum.toString()
            const pageSize = this.pageModel.pagesize.toString()

            const res = await this.$api.selectData.getTabData(pageNum, pageSize)
            this.tbBody = res.data.list
            this.total = res.data.total
        },

        // 搜索
        async searchTabData() {
            const par = this.cascaderModel
            par['room'] = this.room
            par['qianFeiStatus'] = this.paySt
            const res = await this.$api.selectData.searchTab(par)
            this.tbBody = res.data.list
            this.total = res.data.total
        },

        // 导出
        async ExcelClick() {
            const pageNum = this.pageModel.pagenum.toString()
            const pageSize = '100000'
            const res = await this.$api.selectData.getTabData(pageNum, pageSize)
            this.handleExcelClick('设备实时数据', res.data.list)
        },
        // 分页回调
        getPageList() {
            return this.getTabData()
        }
    }
}
</script>

<style lang="scss" scoped>
.font-icon {
    font-size: 40px;
}
</style>
