<template>
    <div class="zykt-main p-1 h-100 box-border">
        <div class="d-flex ai-center py-1">
            <zy-cascader
                class="mr-1"
                ref="zyCascader"
                expand-trigger="click"
                :cascaderData="cascaderData"
                lazy
                @lazy-load="handleCascaderLazyLoad"
                @change="handleCascaderChange"
            ></zy-cascader>
            <el-input
                class="mr-1"
                v-model="room"
                placeholder="户号"
                style="width:120px"
            ></el-input>

            <el-date-picker
                class="mr-1"
                v-model="picker"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <el-button type="primary" @click="searchTabData">
                搜索
            </el-button>
            <el-button type="primary" @click="ExcelClick">
                <i class="iconfont icon-daochubiaoge"></i>
                导出
            </el-button>
        </div>
        <zy-table
            :tbHead="tbHead"
            :tbBody="tbBody"
            height="540px"
            highlightCurrentRow
        >
        </zy-table>
        <zy-page
            class="search-site-page mt-1"
            @current-change="handlePageCurrentChange"
            @size-change="handlePageSizeChange"
            :pageModel="pageModel"
            :total="total"
            :pageSizes="[15, 20, 50, 300]"
        ></zy-page>
    </div>
</template>
<script>
import ZyTable from '@/components/ZyTable/index'
import ZyPage from '@/mixins/ZyPage'
import Excel from '@/mixins/excel'
import cascader from '@/mixins/select'
export default {
    components: { ZyTable },
    mixins: [ZyPage, Excel, cascader],
    data() {
        return {
            room: '',
            picker: [],

            // 表头
            tbHead: [
                // {
                //     prop: 'id',
                //     label: '序号'
                // },
                {
                    prop: 'acname',
                    label: '小区'
                },
                {
                    prop: 'building',
                    label: '楼栋'
                },
                {
                    prop: 'unit',
                    label: '单元'
                },
                {
                    prop: 'room',
                    label: '户号'
                },
                {
                    prop: 'totalMoney',
                    label: '总金额'
                },
                {
                    prop: 'money',
                    label: '充值金额'
                },
                {
                    prop: 'type',
                    label: '充值方式'
                },
                {
                    prop: 'operator',
                    label: '操作人'
                },
                {
                    prop: 'recordTime',
                    label: '充值时间',
                    sortable: true,
                    width: 170
                }
            ],

            // 内容
            tbBody: []
        }
    },
    created() {
        this.getPayMsg()
    },
    methods: {
        // 充值信息
        async getPayMsg() {
            const pageNum = this.pageModel.pagenum.toString()
            const pageSize = this.pageModel.pagesize.toString()
            const res = await this.$api.pay.getPayMsg(pageNum, pageSize)
            this.tbBody = res.data.list
            this.total = parseInt(res.msg)
        },

        // 搜索
        async searchTabData() {
            const pageNum = this.pageModel.pagenum.toString()
            const pageSize = this.pageModel.pagesize.toString()
            const apartmentComplexName = this.cascaderModel.apartmentComplexName
            const building = this.cascaderModel.building
            const unit = this.cascaderModel.unit
            const room = this.room
            const startTime = this.picker[0]
            const endTime = this.picker[1]
            const res = await this.$api.pay.getPayMsg(
                pageNum,
                pageSize,
                apartmentComplexName,
                building,
                unit,
                room,
                startTime,
                endTime
            )
            this.tbBody = res.data.list
            this.total = parseInt(res.msg)
        },

        async ExcelClick() {
            const pageNum = this.pageModel.pagenum.toString()
            const pageSize = this.pageModel.pagesize.toString()
            const apartmentComplexName = this.cascaderModel.apartmentComplexName
            const building = this.cascaderModel.building
            const unit = this.cascaderModel.unit
            const room = this.room
            const startTime = this.picker[0]
            const endTime = this.picker[1]
            const res = await this.$api.pay.getPayMsg(
                pageNum,
                pageSize,
                apartmentComplexName,
                building,
                unit,
                room,
                startTime,
                endTime
            )
            this.handleExcelClick('充值历史数据', res.data.list)
        },
        // 分页回调
        getPageList() {
            return this.getPayMsg()
        }
    }
}
</script>
